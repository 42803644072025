import React from 'react';

import snowflakeLogo from '../assets/vendor_logos/snowflake.png';
import { IntegrationField } from '../components';
import { SettingsStandard } from '../components/SettingsStandard';
import { SetupOneStepConnect } from '../components/SetupOneStepConnect';
import { VendorCategory, Vendor, VendorKey, IntegrationFieldType } from '../types';

import { ATTENTIVE_FEATURES } from './attentive_features';

export const snowflake: Vendor = {
  logo: snowflakeLogo,
  category: VendorCategory.CUSTOMER_DATA_PLATFORM,
  whatYoullNeedItems: ['A Snowflake account.'],
  associatedAttentiveFeatures: [
    {
      feature: ATTENTIVE_FEATURES.LIST_GROWTH,
      description: 'MARKETING COPY TO EXPLAIN THE BENEFITS OF THE INTEGRATION.',
    },
  ],
  companyWebsite: 'https://snowflake.com/',
  salesEmail: '???',
  infoPagesEnabled: true,
  learnSubtext: 'Gain access to Attentive event streams directly from your Snowflake instance',
  jointValueProp:
    'Write Snowflake queries to pull out data sets from sign ups, opt outs, purchases, and more.',
  helpLinks: [
    {
      href: 'https://docs.attentive.com/pages/developer-guides/sftp-solution/attentive-sftp-data-feeds/#sample-files',
      label: 'snowflake integration guide',
    },
  ],
  setupComponent(onComplete) {
    return (
      <SetupOneStepConnect
        vendorKey={VendorKey.VENDOR_SNOWFLAKE_DATA_SHARE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountIdentifier',
            type: IntegrationFieldType.TEXT,
            label: 'Account Identifier',
            required: true,
            placeholder: '<org_name>.<account_name>',
          }),
        ]}
      />
    );
  },
  settingsComponent(onComplete) {
    return (
      <SettingsStandard
        vendorKey={VendorKey.VENDOR_SNOWFLAKE_DATA_SHARE}
        onComplete={onComplete}
        fields={[
          IntegrationField.Config({
            name: 'accountIdentifier',
            type: IntegrationFieldType.TEXT,
            label: 'Account Identifier',
            disabled: true,
            required: true,
          }),
          IntegrationField.Config({
            name: 'tableName',
            type: IntegrationFieldType.TEXT,
            label: 'Table Name',
            disabled: true,
            required: true,
          }),
        ]}
      />
    );
  },
};
